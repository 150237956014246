import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { InvestmentGrowthCalculator } from '../../../components/calculators'
import { anchorLinkSections, CrossSellTout } from './constants'
import { usePages } from '../constants'

const TaxesInvestingPage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, wealthManagementPage } = usePages()

  const pageData = [
    {
      sectionTitle: t('TaxesInvestingPage.subTitle', {
        defaultValue: 'How do taxable, tax-deferred and tax-free investment returns compare?',
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('TaxesInvestingPage.textContent', {
                  defaultValue:
                    "When you're ready to better understand the difference between returns for taxable, tax-deferred and tax-free investments, this calculator is ready to help.",
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: wealthManagementPage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('TaxesInvestingPage.pageTitle', {
              defaultValue: 'Investment Tax Comparison Calculator',
            })}
            pathname="/calculators/wealth-management"
          />
          <CalculatorSectionModule
            pageTitle={t('TaxesInvestingPage.title', {
              defaultValue: 'Wealth Management Calculators',
            })}
            sections={pageData}
            calculator={<InvestmentGrowthCalculator />}
            anchorLinkSections={anchorLinkSections()}
          />
          <CrossSellTout />
        </Layout>
      )}
    </StaticTemplate>
  )
}

TaxesInvestingPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { TaxesInvestingPage }
export default TaxesInvestingPage
