import { useTranslation } from 'react-i18next'
import { ElevatedTitleCentered } from '../../../components/modules/ElevatedTitleCentered'

const anchorLinkSections = () => {
  const [t] = useTranslation()
  const data = [
    {
      title: t('CalculatorPageWealth.sidebarHeader', {
        defaultValue: 'Calculators',
      }),
      anchorLinks: [
        {
          to: '/calculators/wealth-management',
          name: t('CalculatorPageWealth.sidebarLink1', {
            defaultValue: 'How do taxable, tax-deferred and tax-free investment returns compare?',
          }),
        },
      ],
    },
    {
      title: t('CalculatorPageWealth.sidebarHeader2', {
        defaultValue: 'More Calculators',
      }),
      subtitle: t('CalculatorPageWealth.sidebarSubtitle', {
        defaultValue: 'Powered by CUSO Financial Services, L.P.',
      }),
      anchorLinks: [
        {
          to: 'https://elevations.cusonet.com/retirement/',
          name: t('CalculatorPageWealth.sidebarLink2', {
            defaultValue: 'How much will I need to save for retirement?',
          }),
          target: '_blank',
        },
        {
          to: 'https://elevations.cusonet.com/investing/',
          name: t('CalculatorPageWealth.sidebarLink3', {
            defaultValue: 'How do higher returns impact the total value of my investments?',
          }),
          target: '_blank',
        },
        {
          to: 'https://elevations.cusonet.com/ira-options/',
          name: t('CalculatorPageWealth.sidebarLink4', {
            defaultValue: 'How much can I contribute to an IRA?',
          }),
          target: '_blank',
        },
        {
          to: 'https://elevations.cusonet.com/401k/',
          name: t('CalculatorPageWealth.sidebarLink5', {
            defaultValue: 'How much will my 401(k) be worth?',
          }),
          target: '_blank',
        },
        {
          to: 'https://elevations.cusonet.com/college/',
          name: t('CalculatorPageWealth.sidebarLink6', {
            defaultValue: 'How much will I need to save for college?',
          }),
          target: '_blank',
        },
        {
          to: 'https://elevations.cusonet.com/life-insurance/',
          name: t('CalculatorPageWealth.sidebarLink7', {
            defaultValue: 'How much life insurance do I need?',
          }),
          target: '_blank',
        },
      ],
    },
  ]
  return data
}

export { anchorLinkSections }
export default anchorLinkSections

const CrossSellTout = () => {
  const [t] = useTranslation()

  const richHeadline = {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            marks: [],
            value: t('CalculatorPageWealth.ctaHeadlineMortgage', {
              defaultValue:
                'Does your financial plan add up? We can help you build a sound strategy to reach your goals.',
            }),
            data: {},
          },
        ],
        data: {},
      },
    ],
  }

  return (
    <ElevatedTitleCentered
      richHeadline={richHeadline}
      buttonUrl="/financial-planning"
      buttonCta={t('CalculatorPageWealth.ctaButton', {
        defaultValue: 'Learn more',
      })}
      buttonAlt={t('CalculatorPageWealth.ctaButtonAlt', {
        defaultValue: 'Learn more about financial planning',
      })}
    />
  )
}

export { CrossSellTout }
